import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { Button, IconButton, Tooltip as ToolTip, Typography, MenuItem, Menu, Badge } from '@mui/material';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
// import { useHistory } from 'react-router-dom';
import ScheduleIcon from '@mui/icons-material/Schedule';
import VideocamIcon from '@mui/icons-material/Videocam';
import YouTubeIcon from '@mui/icons-material/YouTube';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import ListIcon from '@mui/icons-material/List';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import QueuePlayNextIcon from '@mui/icons-material/QueuePlayNext';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import DoneAllIcon from '@mui/icons-material/DoneAll';
// import LowPriorityIcon from '@mui/icons-material/LowPriority';
// import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
// import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
// import PhonelinkSetupIcon from '@mui/icons-material/PhonelinkSetup';
import LinkIcon from '@mui/icons-material/Link';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import DeleteIcon from '@mui/icons-material/Delete';
import TheatersIcon from '@mui/icons-material/Theaters';
import VisibilityIcon from '@mui/icons-material/Visibility';
// import MovieIcon from '@mui/icons-material/Movie';
// import PlayArrowIcon from '@mui/icons-material/PlayArrow'
// import StopIcon from '@mui/icons-material/Stop'
import ShowChartIcon from '@mui/icons-material/ShowChart';
import EditIcon from '@mui/icons-material/Edit';
import GetAppIcon from '@mui/icons-material/GetApp';
import ChatIcon from '@mui/icons-material/Chat';
import SpeakerNotesOffIcon from '@mui/icons-material/SpeakerNotesOff';
import moment from 'moment'
import BrightcoveVideoPlayer from './BrightcoveVideoPlayer';
import YoutubeVideoPlayer from './YoutubeVideoPlayer'
import TicktBoxVideoPlayer from './TicktBoxVideoPlayer'
import { getTicktBoxStream } from '../services/videoService'
import { useOktaAuth } from '@okta/okta-react';
import config from '../config.json';
import StandardDialog from './standard/StandardDialog';
import UploadSessionFileModal from "./UploadSessionFileModal";

const PREFIX = 'SessionDetailsHeader';

const classes = {
    root: `${PREFIX}-root`,
    modal: `${PREFIX}-modal`,
    paper: `${PREFIX}-paper`,
    header: `${PREFIX}-header`,
    body: `${PREFIX}-body`,
    headerText: `${PREFIX}-headerText`,
    footer: `${PREFIX}-footer`,
    button: `${PREFIX}-button`,
    info: `${PREFIX}-info`,
    group: `${PREFIX}-group`,
    label: `${PREFIX}-label`,
    top: `${PREFIX}-top`,
    bottom: `${PREFIX}-bottom`,
    pageControls: `${PREFIX}-pageControls`,
    titlegroup: `${PREFIX}-titlegroup`,
    chip: `${PREFIX}-chip`,
    pfm: `${PREFIX}-pfm`,
    textIcon: `${PREFIX}-textIcon`,
    textRow: `${PREFIX}-textRow`,
    youtube: `${PREFIX}-youtube`,
    actionListRow: `${PREFIX}-actionListRow`,
    buttongroup: `${PREFIX}-buttongroup`,
    buttongroupLeft: `${PREFIX}-buttongroupLeft`,
    buttongroupRight: `${PREFIX}-buttongroupRight`,
    chatToggle: `${PREFIX}-chatToggle`,
    selectField: `${PREFIX}-selectField`,
    linksCol: `${PREFIX}-linksCol`,
    videoPlayerContainer: `${PREFIX}-videoPlayerContainer`,
    countDownOverlay: `${PREFIX}-countDownOverlay`,
    playlistPosition: `${PREFIX}-playlistPosition`,
    completeButton: `${PREFIX}-completeButton`,
    deleteIconButton: `${PREFIX}-deleteIconButton`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')({
    [`& .${classes.root}`]: {
        display: 'flex',
        flexDirection: 'column',
        padding: '0.5rem'
    },
    [`& .${classes.modal}`]: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        paddingTop: '30px',
        paddingBottom: '30px',
        width: '100vw',
        justifyContent: 'center'
    },
    [`& .${classes.paper}`]: {
        display: 'flex',
        flexDirection: 'column',
        outline: 'none',
        width: '40%',
        minWidth: '300px',
        padding: '15px 0 0 0',
        maxHeight: '98%'
    },
    [`& .${classes.header}`]: {
        display: 'flex',
        flexDirection: 'row',
        padding: '0 15px 0 15px',
        marginBottom: '6px'
    },
    [`& .${classes.body}`]: {
        padding: '0 15px 0 15px',
        overflowY: 'auto',
        marginTop: '5rem'
    },
    [`& .${classes.headerText}`]: {
        margin: 'auto 0 auto 0',
        whiteSpace: "nowrap"
    },
    [`& .${classes.footer}`]: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        padding: '0 0 15px 0',
        marginTop: '5rem'
    },
    [`& .${classes.button}`]: {
        margin: '3px'
    },
    // category: {
    //     // flexBasis: '20%'
    //     textAlign: 'right',
    //     marginRight: '6px'
    // },
    [`& .${classes.info}`]: {
        // display: 'flex',
        // flexDirection: 'row',
        // justifyContent: 'space-evenly',
        width: '100%'
    },
    // segment: {
    //     maxWidth: '400px',
    //     minWidth: '300px',
    //     width: '40%',
    //     // marginLeft: 'auto',
    //     margin: '12px 24px 12px 24px',
    //     padding: '6px',
    //     border: '2px solid #eee',
    //     borderRadius: '8px',
    // },
    // question: {
    //     display: 'flex',
    //     flexDirection: 'row',
    // },
    // answers: {
    //     display: 'flex',
    //     flexDirection: 'column',
    // },
    // box: {
    //     margin: '6px'
    // },
    [`& .${classes.group}`]: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-evenly'
    },
    // header: {
    //     display: 'flex',
    //     flexDirection: 'row',
    //     alignItems: 'center',
    //     padding: '3px',
    //     borderBottom: '2px solid black'
    // },
    // section: {
    //     marginBottom: '6px'
    // },
    // infogroup: {
    //     margin: '6px auto 6px 1em'
    // },
    // marker: {
    //     display: 'flex',
    //     justifyContent: 'flex-start',
    //     alignItems: 'center'
    // },
    // sidemargin: {
    //     margin: '0 3px 0 3px'
    // },
    [`& .${classes.label}`]: {
        textTransform: 'uppercase',
        fontWeight: 'bold'
    },
    [`& .${classes.top}`]: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        // alignItems: 'center'
        // flexWrap: 'wrap'
        // alignItems: 'baseline'
    },
    [`& .${classes.bottom}`]: {
        position: 'relative',
        display: 'flex',
        // flexDirection: 'row-reverse',
        flexDirection: 'row',
        justifyContent: 'space-between',
        // alignItems: 'center'
        // flexWrap: 'wrap'
        // alignItems: 'baseline'
    },
    [`& .${classes.pageControls}`]: {
        // position: 'absolute',
        // right: 0
    },
    [`& .${classes.titlegroup}`]: {
        display: 'flex',
        flexDirection: 'column',
        marginBottom: '8px',
        height: 'auto',
        position: 'relative',
        overflow: 'hidden',
    },
    [`& .${classes.chip}`]: {
        margin: '4px',
        fontSize: '0.6125rem'
    },
    [`& .${classes.pfm}`]: {
        margin: '1px',
        backgroundColor: '#6C54A4'
    },
    [`& .${classes.textIcon}`]: {
        marginRight: '0.5em'
    },
    [`& .${classes.textRow}`]: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    [`& .${classes.youtube}`]: {
        margin: '1px',
        backgroundColor: '#e52d27'//'#b31217'
    },
    [`& .${classes.actionListRow}`]: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between'
    },
    [`& .${classes.buttongroup}`]: {
        marginTop: 'auto'
    },
    [`& .${classes.buttongroupLeft}`]: {
        marginTop: 'auto',
        marginRight: '1rem'
    },
    [`& .${classes.buttongroupRight}`]: {
        marginTop: 'auto',
        marginLeft: '1rem'
    },
    [`& .${classes.chatToggle}`]: {
        marginTop: 'auto',
        marginLeft: '1rem'
    },
    [`& .${classes.selectField}`]: {
        flexBasis: '80%',
        maxWidth: '260px',
        minWidth: '220px',
        width: '100%',
    },
    [`& .${classes.linksCol}`]: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginLeft: '8px'
    },
    [`& .${classes.videoPlayerContainer}`]: {
        display: 'flex',
        flexDirection: 'column',
        marginBottom: '8px',
        position: 'relative',
        overflow: 'hidden',
        height: 'inherit',
        width: '100%'
    },
    [`& .${classes.countDownOverlay}`]: {
        height: '100%',
        width: '100%',
        position: 'absolute',
        top: '0',
        left: '0',
        zIndex: 1003,
        backgroundColor: 'rgba(0,0,0, 0.6)',
        color: 'white',
        textShadow: '0 3px 0 black',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        margin: '0 auto',
        fontSize: '36px',
    },
    [`& .${classes.playlistPosition}`]: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    [`& .${classes.completeButton}`]: {
        // margin: '6px',
        marginTop: 'auto',
        marginLeft: '1rem',
        border: '1px solid rgba(0, 0, 0, 0.23)'
    },
    [`& .${classes.deleteIconButton}`]: {
        padding: '0px',
        fontSize: '0px',
        minWidth: '0px',
        color: 'grey',
        "&:hover": { color: "red", backgroundColor: 'transparent' }
    }
});

const linkRedirect = link => {
    const win = window.open(link, '_blank');
    win.focus();
};

const formatTime = (time, noMs) => {
    const s = Math.floor(time) % 60
    const ms = !noMs && (time - Math.floor(time))
    const m = Math.floor(time / 60) % 60
    const h = Math.floor(time / 60 / 60)
    return `${h.toString().padStart(2, '0')}:${m.toString().padStart(2, '0')}:${ms ? (s + ms).toFixed(3).padStart(6, '0') : s.toString().padStart(2, '0')}`
}

// Session time to local time based on user browser
const sessionTime = (time) => {
    const localTimezone = moment.tz.guess()
    return moment.tz(time, localTimezone).format('MMMM Do YYYY, h:mm a z')
}

const SessionDetailsHeader = React.memo(({
    currentView,
    allowEdit,
    allowBalance,
    allowTechCheck,
    allowLiveDial,
    allowRewards,
    onViewChange,
    onOpenLink,
    onEditClick,
    onSegmentExportClick,
    onSessionCompleteClick,
    uploadSessionFile,
    getAllSessionFiles,
    getSessionFile,
    deleteSessionFile,
    id,
    type,
    sessionName,
    scheduledStartTime,
    videoTitle,
    hasSurvey,
    surveyType,
    surveyId,
    externalRecruit,
    recruitPlatform,
    externalRecruitId,
    acuityTechCheck,
    hasZoomMeeting,
    zoomHostId,
    zoomMeetingId,
    isDialSession,
    dialMode,
    sessionStartTime,
    contentStartTime,
    videoWatermark,
    watermarkOpacity,
    watermarkStyle,
    videoPlatform,
    isLiveSession,
    playerId,
    videoId,
    videoPlayerRef,
    internalVideoName,
    multiSection,
    videoIndex,
    playlistLength,
    startVideo,
    started,
    complete,
    videoComplete,
    videoLength,
    clientDialViewer,
    hideVideo,
    liveStreamStatus,
    linksMenuAnchor,
    onLinksMenuClose,
    filesMenuAnchor,
    onFilesMenuClose,
    onOpenFile,
    showChat,
    chatOpen,
    toggleChat,
    chatNotification,
    allowComplete
}) => {

    const { oktaAuth } = useOktaAuth();

    // const [chatOpen, setChatOpen] = useState(false)
    const [currentVideoIndex, setCurrentVideoIndex] = useState(0)
    const [currentVideoTime, setCurrentVideoTime] = useState(0);
    // State for determining if the video player is hovered
    const [hover, setHover] = useState(false);
    const [ratio, setRatio] = useState()
    const [openAddFileModal, setOpenAddFileModal] = useState(false);
    const [allFiles, setAllFiles] = useState([]);
    const [selectedFile, setSelectedFile] = useState(null);
    const [hovered, setHovered] = useState(null);
    const [deleteWarnOpen, setDeleteWarnOpen] = useState(false);

    const checkFileList = useCallback(async () => {
        const data = await getAllSessionFiles();
        setAllFiles(data.map(i => {
            let key = i.Key.split('/').slice(2).join('/');
            let filename = i.Key.split('_').slice(2).join('_');
            return { key: key, name: filename }
        }));
    }, [getAllSessionFiles])

    useEffect(() => {
        checkFileList();
    }, [checkFileList])

    useEffect(() => {
        setCurrentVideoIndex((!(complete || videoComplete) && videoIndex) || 0)
    }, [videoIndex, complete, videoComplete])

    const setVideoAspectRatio = useCallback((aspectRatio) => {
        setRatio(aspectRatio)
    }, [])

    const onVideoTimeChange = useCallback((event) => {
        const time = event.target.player ? event.target.player.currentTime() * 1000 : event.target.currentTime * 1000;
        setCurrentVideoTime(time);
    }, []);

    const videoStreamData = useCallback(async (drmType) => {
        const token = oktaAuth.getAccessToken()
        const status = await getTicktBoxStream(videoId, drmType, token)
        // console.log('GOT TB STREAM DATA', status)
        return status
    }, [videoId, oktaAuth])

    const VideoPlayer = useMemo(() => {
        switch (videoPlatform) {
            case 'Brightcove':
                return BrightcoveVideoPlayer
            case 'YouTube':
                return YoutubeVideoPlayer
            case 'ticktBox':
                return TicktBoxVideoPlayer
            // case 'Twitch': 
            //     break;
            default:
                return null
        }
    }, [videoPlatform])

    const videoStyle = useMemo(() => (
        { height: '150px', width: ratio ? `calc(150px / ${ratio})` : '300px' }
    ), [ratio])

    const downloadFile = (url, file) => {
        fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': `application/octet-stream`,
            },
        }).then(response => response.blob())
            .then(blob => {
                const url = window.URL.createObjectURL(new Blob([blob]));
                const link = document.createElement('a');
                link.href = url;
                link.download = file.name;

                document.body.appendChild(link);

                link.click();

                link.parentNode.removeChild(link);
            })
    }

    return (
        <Root>
            <UploadSessionFileModal openAddFileModal={openAddFileModal} checkFileList={checkFileList} uploadSessionFile={uploadSessionFile} setOpenAddFileModal={setOpenAddFileModal} />
            <StandardDialog
                open={deleteWarnOpen}
                title={`Confirm Delete?`}
                content={`Are you sure you want to Delete this file?`}
                submitLabel="YES"
                onSubmit={async () => {
                    await deleteSessionFile(selectedFile.key);
                    setAllFiles(allFiles.filter(i => i.key !== selectedFile.key));
                    setDeleteWarnOpen(false)
                }}
                cancelLabel="NO"
                onCancel={() => {
                    setDeleteWarnOpen(false)
                }}
            >
            </StandardDialog>
            <div className={classes.root}>
                <div className={classes.top}>
                    <div className={classes.titlegroup}>
                        <div className={classes.textRow}>
                            <Typography variant="h3">{sessionName}</Typography>
                            {allowEdit && <>
                                <IconButton color="inherit" onClick={onEditClick}>
                                    <EditIcon />
                                </IconButton>
                                <IconButton color="inherit" onClick={onSegmentExportClick}>
                                    <GetAppIcon />
                                </IconButton>
                            </>}
                        </div>
                        <div className={classes.textRow}>
                            {videoPlatform === 'YouTube' ? <YouTubeIcon className={classes.textIcon} /> : <VideocamIcon className={classes.textIcon} />}
                            <Typography variant="h4">{videoTitle}</Typography>
                        </div>
                        <div className={classes.textRow}>
                            <ScheduleIcon className={classes.textIcon} />
                            <Typography variant="h5">{sessionTime(scheduledStartTime)}</Typography>
                        </div>
                        {/* {allowComplete && <div>
                            <Button className={classes.completeButton} variant="outlined" color="inherit" onClick={onSessionCompleteClick} startIcon={<DoneAllIcon />} disabled={complete || !videoComplete}>
                                COMPLETE SESSION
                            </Button>
                        </div>} */}
                    </div>
                    {!hideVideo && VideoPlayer && <div className={classes.titlegroup}>
                        <div
                            className={classes.videoPlayerContainer}
                            onMouseEnter={() => {
                                setHover(true);
                            }}
                            onMouseLeave={() => {
                                setHover(false);
                            }}>
                            {(videoLength && hover) && <Typography
                                variant="h2"
                                className={classes.countDownOverlay}
                            >
                                {(videoLength - currentVideoTime > 0) ? formatTime((videoLength - currentVideoTime) / 1000, true) : formatTime(0)}
                            </Typography>}
                            <VideoPlayer
                                videoRef={videoPlayerRef}
                                wm={videoWatermark}
                                wmOpacity={watermarkOpacity}
                                watermarkStyle={watermarkStyle}
                                playerId={playerId}
                                videoId={videoId}
                                multiSection={multiSection}
                                videoIndex={currentVideoIndex}
                                sessionId={id}
                                startVideo={startVideo}
                                alreadyStarted={started}
                                sessionStartTime={contentStartTime && contentStartTime[currentVideoIndex] ? Date.parse(contentStartTime[currentVideoIndex] + 'Z') /*+ connectionOffset*/ : null}
                                sessionComplete={complete || videoComplete}
                                onTimeUpdate={onVideoTimeChange}
                                isLive={isLiveSession}
                                liveStreamStatus={liveStreamStatus}
                                videoStreamData={videoStreamData}
                                onMetadataLoaded={setVideoAspectRatio}
                                style={videoStyle}
                            />
                        </div>
                        {multiSection && <Typography
                            variant="caption"
                            className={classes.playlistPosition}
                        >
                            {internalVideoName?.[currentVideoIndex] ? `${internalVideoName[currentVideoIndex]} (${currentVideoIndex + 1}/${playlistLength})` : `VIDEO ${currentVideoIndex + 1} OF ${playlistLength}`}
                        </Typography>}
                    </div>}
                </div>
                <div className={classes.bottom}>
                    {/* <div className={classes.pageControls}> */}
                    <div className={classes.actionListRow}>
                        <ToggleButtonGroup
                            className={classes.buttongroupLeft}
                            value={currentView}
                            exclusive
                            onChange={onViewChange}
                        >
                            {allowBalance && <ToggleButton value="balance">
                                <ToolTip title="Balance">
                                    <PlaylistAddCheckIcon />
                                </ToolTip>
                            </ToggleButton>}
                            <ToggleButton value="guests">
                                <ToolTip title="Guest Management">
                                    <PeopleAltIcon />
                                </ToolTip>
                            </ToggleButton>
                            <ToggleButton value="email">
                                <ToolTip title="Bulk Actions">
                                    <ListIcon />
                                </ToolTip>
                            </ToggleButton>
                        </ToggleButtonGroup>
                        <ToggleButtonGroup
                            className={classes.buttongroup}
                            value={currentView}
                            exclusive
                            onChange={onViewChange}>
                            {allowTechCheck && <ToggleButton value="techcheck">
                                <ToolTip title="Tech Check Management">
                                    <QueuePlayNextIcon />
                                </ToolTip>
                            </ToggleButton>}
                            <ToggleButton value="video">
                                <ToolTip title={`${type === 'Focus Group' ? 'Focus Group' : 'Live Video'} Management`}>
                                    {type === 'Focus Group' ? <VisibilityIcon /> : <TheatersIcon />}
                                </ToolTip>
                            </ToggleButton>
                            {(isDialSession && clientDialViewer && allowLiveDial && dialMode !== 'buttonOnly') && <ToggleButton value="dial">
                                <ToolTip title="Live Dial Viewers">
                                    <ShowChartIcon />
                                </ToolTip>
                            </ToggleButton>}
                        </ToggleButtonGroup>
                        <ToggleButtonGroup
                            className={classes.buttongroupRight}
                            value={currentView}
                            exclusive
                            onChange={onViewChange}>
                            {allowRewards && <ToggleButton value="rewards" disabled={!(started && complete)}>
                                <ToolTip title="Rewards Management">
                                    <AttachMoneyIcon />
                                </ToolTip>
                            </ToggleButton>}
                        </ToggleButtonGroup>
                        {allowComplete && <ToggleButton value="complete_session" className={classes.completeButton} onClick={onSessionCompleteClick} disabled={complete || !videoComplete}>
                            <ToolTip title="Complete Session">
                                <DoneAllIcon />
                            </ToolTip>
                        </ToggleButton>}
                    </div>
                    <div className={classes.actionListRow}>
                        <ToggleButton value="files" onChange={onOpenFile}>
                            <ToolTip title="Files">
                                <FileCopyIcon />
                            </ToolTip>
                        </ToggleButton>
                        <Menu
                            id="files-menu"
                            anchorEl={filesMenuAnchor}
                            keepMounted
                            open={Boolean(filesMenuAnchor)}
                            onClose={() => {
                                setHovered(null)
                                onFilesMenuClose();
                            }}
                        >
                            <MenuItem onMouseOver={() => setHovered(null)} onClick={() => {
                                setOpenAddFileModal(true);
                                onFilesMenuClose()
                            }}>Add file(s)...
                            </MenuItem>
                            {allFiles.map(file => {
                                return <MenuItem onClick={() => setSelectedFile(file)} onMouseOver={() => setHovered(file.key)} style={{ display: 'flex', justifyContent: 'space-between' }} ><div onClick={async () => {
                                    const url = await getSessionFile(file.key);
                                    setSelectedFile(file);
                                    await downloadFile(url, file);
                                    setHovered(null);
                                    onFilesMenuClose()
                                }}>{file.name}</div> {hovered === file.key ? <Button className={classes.deleteIconButton}><DeleteIcon onClick={() => setDeleteWarnOpen(true)} /></Button> : <div style={{ paddingLeft: 30 }}></div>}</MenuItem>
                            })}
                        </Menu>
                        <ToggleButton className={classes.chatToggle} value="links" onChange={onOpenLink}>
                            <ToolTip title="Links">
                                <LinkIcon />
                            </ToolTip>
                        </ToggleButton>
                        <Menu
                            id="links-menu"
                            anchorEl={linksMenuAnchor}
                            keepMounted
                            open={Boolean(linksMenuAnchor)}
                            onClose={onLinksMenuClose}
                        >
                            {(isDialSession && allowLiveDial) && <MenuItem onClick={() => {
                                linkRedirect(`https://${config.dialUrl}/session/${id}`)
                                onLinksMenuClose()
                            }}>Live Dial Graph</MenuItem>}
                            {(videoId && videoPlatform === 'Brightcove') && <MenuItem onClick={() => {
                                linkRedirect('https://status.brightcove.com/')
                                onLinksMenuClose()
                            }}>Brightcove - Streaming Platform Status</MenuItem>}
                            <MenuItem onClick={() => {
                                linkRedirect('https://twitter.com/mailchimpstatus')
                                onLinksMenuClose()
                            }}>Mailchimp Status</MenuItem>
                            {(externalRecruit && recruitPlatform === "QTC") && [
                                <MenuItem onClick={() => {
                                    linkRedirect(`https://screenengine.co1.qualtrics.com/survey-builder/${externalRecruitId}/edit`)
                                    onLinksMenuClose()
                                }}>Qualtrics Registration - Survey Builder</MenuItem>,
                                <MenuItem onClick={() => {
                                    linkRedirect(`https://screenengine.qualtrics.com/jfe/form/${externalRecruitId}`)
                                    onLinksMenuClose()
                                }}>Qualtrics Registration - Survey Link</MenuItem>
                            ]}
                            {(externalRecruit && recruitPlatform === "PFM") &&
                                <MenuItem onClick={() => {
                                    linkRedirect(`https://${config.pfmUrl}/cms/dashboard/screenings/Navigation/screening/tab/snapshot?id=${externalRecruitId}`)
                                    onLinksMenuClose()
                                }}>Preview Free Movies - Screening Balance</MenuItem>
                            }
                            {acuityTechCheck && <MenuItem onClick={() => {
                                linkRedirect(`https://secure.acuityscheduling.com/appointments.php`)
                                onLinksMenuClose()
                            }}>Acuity - Appointment Calendar</MenuItem>}
                            {hasZoomMeeting && <MenuItem onClick={() => {
                                linkRedirect(`https://zoom.us/user/${zoomHostId}/meeting/${zoomMeetingId}`)
                                onLinksMenuClose()
                            }}>Zoom - Meeting Details</MenuItem>}
                            {(hasSurvey && surveyType === 'Decipher') &&
                                <MenuItem onClick={() => {
                                    linkRedirect(`https://seasi.decipherinc.com/rep/${surveyId}:dashboard`)
                                    onLinksMenuClose()
                                }}>Decipher - Response Summary</MenuItem>
                            }
                            {(hasSurvey && surveyType === 'Decipher') &&
                                <MenuItem onClick={() => {
                                    linkRedirect(`https://seasi.decipherinc.com/apps/report/${surveyId}`)
                                    onLinksMenuClose()
                                }}>Decipher - Cross Tabs</MenuItem>
                            }
                            {(hasSurvey && surveyType === 'Decipher') &&
                                <MenuItem onClick={() => {
                                    linkRedirect(`https://seasi.decipherinc.com/apps/portal/#/projects/detail/${surveyId}`)
                                    onLinksMenuClose()
                                }}>Decipher - Overview</MenuItem>
                            }
                            {(hasSurvey && surveyType === 'Decipher') &&
                                <MenuItem onClick={() => {
                                    linkRedirect(`https://seasi.decipherinc.com/apps/report/${surveyId}#!/custom/list`)
                                    onLinksMenuClose()
                                }}>Decipher - Saved Reports</MenuItem>
                            }
                            {(hasSurvey && surveyType === 'Decipher') &&
                                <MenuItem onClick={() => {
                                    linkRedirect(`https://seasi.decipherinc.com/report/${surveyId}?config=GmGrUA&run=1`)
                                    onLinksMenuClose()
                                }}>Decipher - Data Downloads</MenuItem>
                            }
                        </Menu>
                        {showChat && <ToggleButton
                            value="showChat"
                            className={classes.chatToggle}
                            selected={chatOpen}
                            onChange={toggleChat}
                        >
                            <Badge color="secondary" badgeContent={chatNotification} max={9}>
                                {chatOpen ? <SpeakerNotesOffIcon /> : <ChatIcon />}
                            </Badge>
                        </ToggleButton>}
                    </div>
                </div>
            </div >
        </Root>
    );
})

export default (SessionDetailsHeader)